html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

main {
  height: 100vh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.full-height {
  height: calc(100vh - 120px);
}

.abs-img {
  position: absolute;
  z-index: 500;
}

.main-nav {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.logo {
  top: 30px;
  left: 30px;
  background-color: #fff;
  z-index: 500;
  padding: 0 20px;
  cursor: pointer;
}

.ts-logo {
  bottom: 30px;
  right: 30px;
  z-index: 500;
  padding: 10px 10px 5px 10px;
  cursor: pointer;
  opacity: 0.5;
}

.btn {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  background-color: var(--clr-secondary) !important;
  letter-spacing: 0.1em;
  font-size: 14px;
  padding: 15px 30px;
  border-radius: 30px;
  line-height: 1em;
  position: static;
  transition: 0.3s all ease;
  //   border: 2px solid var(--clr-secondary);
  box-shadow: var(--bs-m);
  text-decoration: none !important;
  display: inline-block;
}

.btn:hover {
  // background-color: #77a820;
  border-color: var(--clr-lightest);
  box-shadow: var(--bs-l);
  color: white;
}

.home-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }
  .content {
    max-width: 400px;
    margin-top: 20px;
  }

  .leeward-logo {
    width: 170px !important;
  }
  .truescape-logo {
    width: 80px !important;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1540px) {
  .content {
    max-width: 600px;
    margin-top: 20px;
  }
}
